import SvgIconWrapper from "./SvgIconWrapper";

export default function WritingIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4246_18616)">
          <path
            d="M12.3911 7.73438C12.3911 7.34604 12.0763 7.03125 11.688 7.03125H4.44582C4.05748 7.03125 3.74269 7.34604 3.74269 7.73438C3.74269 8.12271 4.05748 8.4375 4.44582 8.4375H11.688C12.0763 8.4375 12.3911 8.12271 12.3911 7.73438Z"
            fill="currentColor"
          />
          <path
            d="M4.44582 9.84375C4.05748 9.84375 3.74269 10.1585 3.74269 10.5469C3.74269 10.9352 4.05748 11.25 4.44582 11.25H8.84425C9.23259 11.25 9.54737 10.9352 9.54737 10.5469C9.54737 10.1585 9.23259 9.84375 8.84425 9.84375H4.44582Z"
            fill="currentColor"
          />
          <path
            d="M6.09816 16.5938H3.7466C2.97119 16.5938 2.34035 15.9629 2.34035 15.1875V2.8125C2.34035 2.03709 2.97119 1.40625 3.7466 1.40625H12.3911C13.1665 1.40625 13.7974 2.03709 13.7974 2.8125V7.13672C13.7974 7.52505 14.1122 7.83984 14.5005 7.83984C14.8888 7.83984 15.2036 7.52505 15.2036 7.13672V2.8125C15.2036 1.26169 13.9419 0 12.3911 0H3.7466C2.19578 0 0.934097 1.26169 0.934097 2.8125V15.1875C0.934097 16.7383 2.19578 18 3.7466 18H6.09816C6.4865 18 6.80129 17.6852 6.80129 17.2969C6.80129 16.9085 6.4865 16.5938 6.09816 16.5938Z"
            fill="currentColor"
          />
          <path
            d="M16.449 10.1803C15.6266 9.35783 14.2884 9.35779 13.4665 10.1797L9.60623 14.0314C9.52425 14.1132 9.46378 14.214 9.4301 14.3248L8.58941 17.0925C8.51488 17.3379 8.57981 17.6043 8.7589 17.7879C8.89305 17.9254 9.07531 18 9.26223 18C9.32477 18 9.38791 17.9916 9.44989 17.9744L12.2879 17.1883C12.4047 17.156 12.5111 17.094 12.5969 17.0084L16.4491 13.1634C17.2715 12.341 17.2715 11.0028 16.449 10.1803ZM11.7343 15.8825L10.3065 16.278L10.7244 14.9024L13.3291 12.3034L14.3236 13.298L11.7343 15.8825ZM15.4552 12.1686L15.319 12.3046L14.3246 11.3102L14.4604 11.1747C14.7345 10.9006 15.1806 10.9006 15.4547 11.1747C15.7289 11.4489 15.7289 11.8949 15.4552 12.1686Z"
            fill="currentColor"
          />
          <path
            d="M11.688 4.21875H4.44582C4.05748 4.21875 3.74269 4.53354 3.74269 4.92188C3.74269 5.31021 4.05748 5.625 4.44582 5.625H11.688C12.0763 5.625 12.3911 5.31021 12.3911 4.92188C12.3911 4.53354 12.0763 4.21875 11.688 4.21875Z"
            fill="currentColor"
          />
        </g>

        <defs>
          <clipPath id="clip0_4246_18616">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
