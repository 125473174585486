import SvgIconWrapper from "./SvgIconWrapper";

export default function TruckMoving2Icon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
      >
        <path
          d="M23.6371 10.0198L21.8344 8.70138C21.8344 8.70138 21.7268 8.58031 21.7133 8.52649L20.5698 4.67896C20.1931 3.66999 19.211 2.98389 18.1348 2.98389H16.7626V1.71932C16.7626 1.10048 16.2514 0.602724 15.646 0.602724H4.93728C4.43952 0.562365 4.02247 0.992858 3.99557 1.50407C3.99557 1.74622 4.07628 1.97492 4.25117 2.16326C4.42607 2.3516 4.64132 2.43232 4.91038 2.44577H14.9195V9.0108C14.9195 9.62963 15.4173 10.1274 16.0361 10.1274H20.6371L22.1707 11.2036V17.6341H20.3815C19.9241 16.2619 18.4846 15.4278 17.0585 15.7642C16.0899 15.9794 15.3096 16.7059 15.0002 17.6341H8.00461C7.80281 17.0153 7.38576 16.4772 6.82073 16.1274C6.17498 15.7238 5.42161 15.5893 4.68168 15.7642C3.71306 15.9794 2.93278 16.7059 2.62336 17.6341H0.941718C0.4305 17.6207 0.0269062 18.0243 0 18.5355C0 19.0332 0.39014 19.4637 0.914812 19.4637H2.63681C2.85206 20.0826 3.28256 20.6072 3.84759 20.957C4.29154 21.2261 4.80276 21.3606 5.30053 21.3606C5.52923 21.3606 5.75793 21.3337 5.98664 21.2799C6.92836 21.0512 7.66828 20.3651 7.9777 19.4637H15.0002C15.4711 20.8225 16.924 21.6296 18.3635 21.2799C19.3052 21.0512 20.0451 20.3651 20.3546 19.4637H22.8837C23.5026 19.4637 24.0004 18.966 24.0004 18.3471V10.8538C24.0004 10.5444 23.8658 10.235 23.6371 10.0198ZM19.7088 8.28434H16.7626V4.81349H18.1348C18.4577 4.81349 18.7402 5.01528 18.8478 5.28434L19.7088 8.28434ZM18.6998 18.522C18.6998 19.0736 18.2424 19.531 17.6908 19.531C17.1393 19.531 16.6819 19.087 16.6819 18.522C16.6819 17.957 17.1393 17.513 17.6908 17.513C18.2424 17.513 18.6998 17.9704 18.6998 18.522ZM6.32297 18.522C6.32297 19.0736 5.87901 19.531 5.31398 19.531C4.7624 19.531 4.305 19.087 4.305 18.522C4.305 17.957 4.7624 17.513 5.31398 17.513C5.86556 17.513 6.32297 17.9704 6.32297 18.522Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M2.05837 10.1631H8.62349C9.12125 10.1631 9.5383 9.74605 9.5383 9.24829C9.5383 8.75054 9.13471 8.3335 8.62349 8.3335H2.05837C1.54715 8.3335 1.14355 8.75054 1.14355 9.24829C1.14355 9.74605 1.54715 10.1631 2.05837 10.1631Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M3.40407 6.16309H7.68216C8.19338 6.16309 8.59697 5.74605 8.59697 5.24829C8.59697 4.75054 8.19338 4.3335 7.68216 4.3335H3.40407C2.89285 4.3335 2.48926 4.75054 2.48926 5.24829C2.48926 5.74605 2.89285 6.16309 3.40407 6.16309Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M0.914812 14.1631H10.1571C10.6683 14.1631 11.0719 13.7595 11.0719 13.2483C11.0719 12.7371 10.6683 12.3335 10.1571 12.3335H0.914812C0.417047 12.3335 0 12.7371 0 13.2348C0 13.7461 0.403594 14.1496 0.914812 14.1631Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
      </svg>
    </SvgIconWrapper>
  );
}
