import Router from "next/router";

import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { QuickActionItem } from ".";
import Styled from "./index.styles";

export default function QuickActionsForDesktop({
  list,
}: {
  list: QuickActionItem[];
}) {
  return (
    <Styled.desktopContainer>
      <ul>
        {list.map(({ label, path, Icon }) => (
          <li key={label} onClick={() => (path ? Router.push(path) : noop)}>
            <Icon color={COLOR.wh_60} width={24} height={24} />

            <span className="label">{label}</span>
          </li>
        ))}
      </ul>
    </Styled.desktopContainer>
  );
}
