import SvgIconWrapper from "./SvgIconWrapper";

export default function BoxesIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_658_17354)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.32187 0C7.78863 0 7.35635 0.432278 7.35635 0.965519V7.35631H4.64368C4.11043 7.35631 3.67816 7.78859 3.67816 8.32183V14.7126H0.96552C0.432278 14.7126 0 15.1449 0 15.6781V23.0345C0 23.5677 0.432278 24 0.96552 24H8.32186H15.6782H15.6782H23.0345C23.5678 24 24 23.5677 24 23.0345V15.6781C24 15.1449 23.5678 14.7126 23.0345 14.7126H20.3219V8.32183C20.3219 7.78859 19.8896 7.35631 19.3564 7.35631H16.6437V0.965519C16.6437 0.432278 16.2115 0 15.6782 0H12H8.32187ZM14.7127 22.069V16.6437H12.9655V18.6207C12.9655 19.1539 12.5333 19.5862 12 19.5862C11.4668 19.5862 11.0345 19.1539 11.0345 18.6207V16.6437H9.28739V22.069H14.7127ZM15.6782 14.7126H15.6782H12.9655V9.28738H14.7127V11.2644C14.7127 11.7976 15.1449 12.2299 15.6782 12.2299C16.2114 12.2299 16.6437 11.7976 16.6437 11.2644V9.28735H18.3909V14.7126H15.6782ZM22.069 16.6437V22.069H16.6437V16.6437H18.3909V18.6207C18.3909 19.1539 18.8231 19.5862 19.3564 19.5862C19.8896 19.5862 20.3219 19.1539 20.3219 18.6207V16.6437H22.069ZM8.32187 14.7126H11.0345V9.28738H9.28739V11.2644C9.28739 11.7976 8.85511 12.2299 8.32187 12.2299C7.78863 12.2299 7.35635 11.7976 7.35635 11.2644V9.28735H5.60919V14.7126H8.32186H8.32187ZM3.67816 16.6437H1.93104V22.069H7.35634V16.6437H5.60919V18.6207C5.60919 19.1539 5.17692 19.5862 4.64368 19.5862C4.11043 19.5862 3.67816 19.1539 3.67816 18.6207V16.6437ZM12 7.35631H14.7127V1.93104H12.9655V3.90806C12.9655 4.4413 12.5333 4.87357 12 4.87357C11.4668 4.87357 11.0345 4.4413 11.0345 3.90806V1.93104H9.28739V7.35631H12H12Z"
            fill="currentColor"
            fillOpacity="0.6"
          />
        </g>
        <defs>
          <clipPath id="clip0_658_17354">
            <rect width="24" height="24" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
