import { useRouter } from "next/router";

export default function useCheckIsFulfillmentPage() {
  const asPath = useRouter().asPath;

  const isFulfillmentPage = asPath.includes("/fulfillment");

  const isFulfillmentDomesticPage = asPath.includes("/fulfillment/domestic");

  const isFulfillmentOverseasPage = asPath.includes("/fulfillment/overseas");

  const isFulfillmentEventPage = asPath.includes("/fulfillment/event");

  const isFulfillmentDomesticOrOverseasPage =
    isFulfillmentDomesticPage || isFulfillmentOverseasPage;

  return {
    isFulfillmentPage,
    isFulfillmentDomesticPage,
    isFulfillmentOverseasPage,
    isFulfillmentEventPage,
    isFulfillmentDomesticOrOverseasPage,
  };
}
