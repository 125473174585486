import React, { Suspense } from "react";

const isBrowser = typeof window !== "undefined";

export default function SuspenseWrapper({
  children,
  fallback,
}: {
  children: JSX.Element;
  fallback: JSX.Element;
}) {
  if (isBrowser) return <Suspense {...{ children, fallback }} />;

  return fallback;
}
